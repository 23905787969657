<template>
  <AdminLayout>
    <BackButton />
    <v-col cols="auto" id="legal" class="justify-center mb-8 pa-0">
      <v-col cols="auto" class="d-flex flex-column align-center py-0 my-0 mb-4">
        <p class="headline">Impressum</p>
      </v-col>
      <v-col cols="12" class="d-flex flex-column py-0 my-0">
        <p class="pa-0 ma-0 font-weight-bold imprint-spacing text-center">
          {{ $t('imprint.alphaport.name') }}
        </p>
        <p class="pa-0 ma-0 text-center">
          {{ $t('imprint.alphaport.street') }}
        </p>
        <p class="pa-0 ma-0 text-center">
          {{ $t('imprint.alphaport.city') }}
        </p>
        <p class="pa-0 ma-0 text-center">{{ $t('imprint.country') }}</p>
      </v-col>

      <v-col cols="12" class="d-flex flex-column pb-0 my-0">
        <p class="pa-0 ma-0 mb-2 text-center imprint-spacing white--text">
          <a class="fontColor" :href="$t('imprint.alphaport.emailLink')">{{
            $t('imprint.alphaport.email')
          }}</a>
        </p>
      </v-col>
      <v-col cols="12" class="d-flex flex-column pb-0 my-0">
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.ceo')"
        ></p>
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.service')"
        ></p>
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.uid')"
        ></p>
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.fn')"
        ></p>
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.gericht')"
        ></p>
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.firmensitz')"
        ></p>
        <p
          class="pa-0 ma-0 mb-2 text-center"
          v-html="$t('imprint.alphaport.kammer')"
        ></p>
        <p class="pa-0 ma-0 mb-2 text-center">
          Informationspflicht lt. ECG und Mediengesetz:

          <a
            href="https://firmen.wko.at/alphaport-og/ober%c3%b6sterreich/?firmaid=fa167b8f-f94e-4a6e-9f96-a260bf58d2b9"
            >{{ $t('imprint.alphaport.informationspflicht') }}</a
          >
        </p>
        <p class="pa-0 ma-0 mb-6 text-center">
          Gewerbeordnung:
          <a :href="$t('imprint.risLink')">{{ $t('imprint.ris') }}</a>
        </p>
      </v-col>

      <v-col cols="auto" class="d-flex flex-column align-center pb-0 my-0">
        <v-col cols="8 pa-0 ma-0 ">
          <p class="pa-0 ma-0 mt-4 mb-2 text-center">
            {{ $t('imprint.info') }}
          </p>
        </v-col>
      </v-col>
      <v-col cols="auto" class="d-flex flex-column align-center pb-0 my-0">
        <p>
          Icons made by
          <a href="https://www.freepik.com" title="Freepik" class="">Freepik</a>
          from
          <a href="https://www.flaticon.com/" title="Flaticon" class=""
            >www.flaticon.com</a
          >
        </p>
      </v-col>
      <v-col cols="auto" class="d-flex flex-column align-center pb-0 my-0">
        <p>
          Technische Umsetzung:
          <a href="https://www.alphaport.at" title="Alphaport OG" class=""
            >Alphaport</a
          >
        </p>
      </v-col>
    </v-col>

    <v-col
      id="data-policy"
      cols="8"
      class="d-flex flex-column align-center py-0 my-0 mb-4"
    >
      <p class="headline">
        <a :href="$t('imprint.dataLink').toString()">Datenschutzerklärung</a>
      </p>
    </v-col>
    <v-col
      id="contact"
      cols="8"
      class="d-flex flex-column align-center py-0 my-0 mb-4"
    >
    </v-col>
  </AdminLayout>
</template>

<script lang="ts">
import BackButton from '../../components/admin/login/BackButton.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import AdminLayout from '../../components/layout/AdminLayout.vue';

@Component({
  components: { AdminLayout, BackButton },
})
export default class AdminImpressum extends Vue {}
</script>

<style lang="scss" scoped>
a {
  color: black;
}

#legal {
  color: black !important;
}
</style>
