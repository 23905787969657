<template>
  <GeneralLayout>
    <template v-slot:header>
      <GeneralHeader :title="title" />
    </template>
    <template v-slot:main>
      <v-col
        cols="11"
        sm="10"
        md="8"
        lg="6"
        xl="6"
        class="imprint justify-center white--text mb-8"
      >
        <v-col cols="12" class="d-flex flex-column py-0 my-0">
          <p class="pa-0 ma-0 font-weight-bold imprint-spacing text-center">
            {{ $t('imprint.alphaport.name') }}
          </p>
          <p class="pa-0 ma-0 text-center">
            {{ $t('imprint.alphaport.street') }}
          </p>
          <p class="pa-0 ma-0 text-center">
            {{ $t('imprint.alphaport.city') }}
          </p>
          <p class="pa-0 ma-0 text-center">{{ $t('imprint.country') }}</p>
        </v-col>

        <v-col cols="12" class="d-flex flex-column pb-0 my-0">
          <p class="pa-0 ma-0 mb-1 text-center imprint-spacing white--text">
            <a class="fontColor" :href="$t('imprint.alphaport.emailLink')">{{
              $t('imprint.alphaport.email')
            }}</a>
          </p>
        </v-col>
        <v-col cols="12" class="d-flex flex-column pb-0 my-0">
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.ceo')"
          ></p>
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.service')"
          ></p>
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.uid')"
          ></p>
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.fn')"
          ></p>
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.gericht')"
          ></p>
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.firmensitz')"
          ></p>
          <p
            class="pa-0 ma-0 mb-2 text-center"
            v-html="$t('imprint.alphaport.kammer')"
          ></p>
          <p class="pa-0 ma-0 mb-2 text-center">
            Informationspflicht lt. ECG und Mediengesetz:

            <a
              class="fontColor"
              href="https://firmen.wko.at/alphaport-og/ober%c3%b6sterreich/?firmaid=fa167b8f-f94e-4a6e-9f96-a260bf58d2b9"
              >{{ $t('imprint.alphaport.informationspflicht') }}</a
            >
          </p>
          <p class="pa-0 ma-0 mb-6 text-center">
            Gewerbeordnung:
            <a class="fontColor" :href="$t('imprint.risLink')">{{
              $t('imprint.ris')
            }}</a>
          </p>
        </v-col>
        <!-- <v-col cols="8" class="d-flex flex-column pb-0 my-0">
          <strong>{{ $t('imprint.business.name') }}</strong>
          <p class="pa-0 ma-0">{{ $t('imprint.business.type') }}</p>
          <p class="pa-0 ma-0">{{ $t('imprint.business.uid') }}</p>
          <p class="pa-0 ma-0">{{ $t('imprint.business.tax') }}</p>
        </v-col>
        <v-col cols="8" class="d-flex flex-column pb-0 my-0">
          <strong>Österreich:</strong>
          <p class="pa-0 ma-0">{{ $t('imprint.bank.name') }}</p>
          <p class="pa-0 ma-0">{{ $t('imprint.bank.owner') }}</p>
          <p class="pa-0 ma-0">{{ $t('imprint.bank.iban') }}</p>
          <p class="pa-0 ma-0">{{ $t('imprint.bank.bic') }}</p>
        </v-col> -->
        <!-- <v-col cols="8" class="d-flex flex-column pb-0 my-0">
          <a :href="$t('imprint.risLink')" class="white--text">{{
            $t('imprint.ris')
          }}</a>
        </v-col> -->
        <v-col cols="12" class="d-flex flex-column pb-0 my-0">
          <p class="pa-0 ma-0 mt-4 mb-2 text-center">
            {{ $t('imprint.info') }}
          </p>
          <p class="text-center">
            {{ $t('imprint.freepik') }}
            <a
              href="https://www.freepik.com"
              title="Freepik"
              class="white--text"
              >Freepik</a
            >
            {{ $t('imprint.from') }}
            <a
              href="https://www.flaticon.com/"
              title="Flaticon"
              class="white--text"
            >
              {{ $t('imprint.flatIcon') }}</a
            >
          </p>
        </v-col>
        <v-col cols="12" class="d-flex flex-column pb-0 my-0">
          <p class="text-center">
            {{ $t('imprint.developed') }}
            <a
              href="https://www.alphaport.at"
              title="Alphaport OG"
              class="white--text"
              >{{ $t('imprint.alphaport.name') }}</a
            >
          </p>
          <p class="headline text-center data-policy">
            <a :href="$t('imprint.dataLink').toString()"
              >Datenschutzerklärung</a
            >
          </p>
        </v-col>
      </v-col>
    </template>
  </GeneralLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import GeneralHeader from '../../components/base/GeneralHeader.vue';
import GeneralLayout from '../../components/layout/GeneralLayout.vue';

@Component({
  components: {
    GeneralLayout,
    GeneralHeader,
  },
})
export default class Impressum extends Vue {
  get title(): string {
    return 'Impressum';
  }
}
</script>

<style lang="scss" scoped>
.imprint-spacing {
  letter-spacing: 1px;
}

.fontColor {
  color: white !important;
}

.data-policy {
  a {
    color: white !important;
  }
}
</style>
